import * as ACTION from "./actions";
import { AuthApiResult, DefaultApiAction } from "../reducers";

export const INIT_STATE = {
  loading: false,
  error: false,
  logged: false,
  data: null,
  user: null,
  token: null,
};

export default function reducer(
  state: AuthApiResult = INIT_STATE,
  action: DefaultApiAction
): AuthApiResult {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case ACTION.LOGIN_REQUEST:
    case ACTION.REGISTER_REQUEST:
    case ACTION.SET_PASSWORD_REQUEST:
    case ACTION.RECOMMENDATION_REQUEST:
    case ACTION.RESET_PASSWORD_REQUEST:
    case ACTION.USER_INFO_REQUEST:
    case ACTION.SET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        logged: true,
        error: false,
        data: action.payload,
        user: action.payload.user,
        token: action.payload.token,
      };
    case ACTION.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        logged: true,
        error: false,
        user: action.payload.user,
        token: action.payload.token,
      };
    case ACTION.REGISTER_SUCCESS:
    case ACTION.RECOMMENDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case ACTION.USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload,
      };
    case ACTION.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { amended: true },
      };

    case ACTION.LOGIN_FAILURE:
    case ACTION.REGISTER_FAILURE:
    case ACTION.SET_PASSWORD_FAILURE:
    case ACTION.RESET_PASSWORD_FAILURE:
    case ACTION.RECOMMENDATION_FAILURE:
    case ACTION.USER_INFO_FAILURE:
    case ACTION.SET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.errors,
        logged: false,
      };
    default:
      return state;
  }
}
