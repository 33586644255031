export const COMPANY_REQUEST = "COMPANY_REQUEST";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAILURE = "COMPANY_FAILURE";
export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";
export const GET_COMPANY_INFO_REQUEST = "GET_COMPANY_INFO_REQUEST";
export const GET_COMPANY_INFO_SUCCESS = "GET_COMPANY_INFO_SUCCESS";
export const GET_COMPANY_INFO_FAILURE = "GET_COMPANY_INFO_FAILURE";
export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const addCompany = (data?: object) => {
  return {
    method: "POST",
    type: ADD_COMPANY_REQUEST,
    data,
  };
};

export const updateCompany = (id: string, data?: object) => {
  return {
    method: "PUT",
    type: UPDATE_COMPANY_REQUEST,
    data,
    id,
  };
};

export const getCompany = () => {
  return {
    method: "GET",
    type: COMPANY_REQUEST,
  };
};

export const getCompanyInfo = (data?: object) => {
  return {
    method: "GET",
    type: GET_COMPANY_INFO_REQUEST,
    data,
  };
};
