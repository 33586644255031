import React from "react";
import Select from "react-select";

import "./select.scss";

interface option {
  value: any;
  label: string;
}

interface SelectProps {
  options: Array<option>;
  name?: string;
  defaultInputValue?: any;
  placeholder: string;
  onChange: (e: any) => void;
}

const CSelect = ({
  options,
  name,
  defaultInputValue,
  placeholder,
  onChange,
}: SelectProps) => {
  return defaultInputValue ? (
    <Select
      options={options}
      name={name}
      value={defaultInputValue}
      defaultValue={defaultInputValue}
      className="react-select-container"
      onChange={onChange}
      placeholder={placeholder}
      classNamePrefix="react-select"
    />
  ) : (
    <Select
      options={options}
      name={name}
      className="react-select-container"
      onChange={onChange}
      placeholder={placeholder}
      classNamePrefix="react-select"
    />
  );
};

export default CSelect;
