import React from "react";
import "./header.scss";

interface sectionProps {
  title: string;
}

const SectionHeader: React.FC<sectionProps> = ({ title }) => {
  return (
    <div className="section-header">
      <h3>{title}</h3>
    </div>
  );
};

export default SectionHeader;
