import React, { useEffect } from "react";
import { Formik, Form, Field, FormikErrors, FieldProps } from "formik";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState, AuthApiResult } from "../../redux/reducers";
import useLocalStorage from "./../../hooks/useLocalStorage";
import { updatePassword } from "./../../redux/profile/actions";
import displayMessage from "../../helpers/displayMessage";
import { useHistory } from "react-router-dom";
import "./change-password.scss";

interface MyFormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const ChangePasswordForm: React.FC = () => {
  const initialValues: MyFormValues = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const [, setItem] = useLocalStorage("token", "");

  const auth = useSelector<RootState, AuthApiResult>((state) => state.auth);

  const profile = useSelector<RootState, AuthApiResult>(
    (state) => state.profile
  );
  useEffect(() => {
    if (auth.token && auth.logged) {
      setItem(auth.token);
      history.push("/");
    }
    if (auth.error) {
      displayMessage(auth.error, "error");
    }
    if (profile.error) {
      displayMessage(profile.error, "error");
    }
    if (profile.data?.success) {
      displayMessage("Hasło zostało zaaktualizowane", "success");
    }
  }, [auth, history, profile, setItem]);

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: MyFormValues) => {
        const errors: FormikErrors<MyFormValues> = {};
        if (!values.currentPassword) {
          errors.currentPassword = "To pole jest wymagane";
        }
        if (!values.password) {
          errors.password = "To pole jest wymagane";
        } else if (values.password.length < 8) {
          errors.password = "Hasło musi zawierać co najmniej 8 znaków";
        }
        if (!values.confirmPassword) {
          errors.confirmPassword = "To pole jest wymagane";
        } else if (values.confirmPassword.length < 8) {
          errors.confirmPassword = "Hasło musi zawierać co najmniej 8 znaków";
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = "Hasła muszą być identyczne";
        }
        return errors;
      }}
      onSubmit={(values, actions) => {
        dispatch(updatePassword({ ...values }));
        actions.setSubmitting(false);
      }}
    >
      {() => (
        <Form className="change-pass-form">
          <div className="row">
            <div className="col-lg-10 col-xl-6">
              <Field name="currentPassword">
                {({ field, meta }: FieldProps) => (
                  <fieldset>
                    <input
                      type="password"
                      placeholder="Aktualne hasło"
                      className={meta.error && "invalid"}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </fieldset>
                )}
              </Field>
            </div>
            <div className="col-lg-5 col-xl-3">
              <Field name="password">
                {({ field, meta }: FieldProps) => (
                  <fieldset>
                    <input
                      type="password"
                      placeholder="Nowe hasło"
                      className={meta.error && "invalid"}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </fieldset>
                )}
              </Field>
            </div>
            <div className="col-lg-5 col-xl-3">
              <Field name="confirmPassword">
                {({ field, meta }: FieldProps) => (
                  <fieldset>
                    <input
                      type="password"
                      placeholder="Potwierdź nowe hasło"
                      className={meta.error && "invalid"}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </fieldset>
                )}
              </Field>
            </div>
          </div>

          <div className="action-wrapper">
            <Button
              className={auth.loading ? "submit loading" : "submit"}
              type="submit"
            >
              Zmień hasło
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
