import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./../../redux/reducers";
import { useHistory } from "react-router-dom";

import routes from "./../../routes";

const Home: React.FC = () => {
  const state = useSelector<RootState, RootState>((state) => state);
  const history = useHistory();
  const { company, home } = routes;
  useEffect(() => {
    if (state.auth.user != null) {
      if (!state.auth.user?.registered) {
        history.push(company);
      } else {
        history.push(home);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return null;
};

export default Home;
