import React, { useEffect, useState } from "react";
import logo from "../../../images/logo.png";
import register from "../../../images/register.png";
import RegisterForm from "../../components/registerForm";
import PasswordForm from "../../components/passwordForm";
import routes from "../../routes";
import { Link, RouteComponentProps } from "react-router-dom";

interface MatchParams {
  uuid: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const Register: React.FC<Props> = ({ match }) => {
  const [isPasswordPage, setIsPasswordPage] = useState(false);
  const uuid: string = match.params.uuid;

  useEffect(() => {
    if (uuid) {
      setIsPasswordPage(true);
    }
  }, [uuid]);

  return (
    <article className="login-page">
      <div className="row no-gutters">
        <div className="col-6">
          <div className="img-wrapper">
            <img src={register} alt="register page background" />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="content">
            <img src={logo} className="App-logo" alt="logo" />
            {isPasswordPage ? (
              <>
                <h3>Ustal hasło</h3>
                <PasswordForm uuid={uuid} />
              </>
            ) : (
              <>
                <h3>Załóż konto</h3>
                <RegisterForm />
                <div className="footer">
                  <p>Masz już konto?</p>
                  <Link
                    to={routes.login}
                    className="button black-squeeze-button"
                  >
                    Zaloguj się
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default Register;
