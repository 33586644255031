import React, { useEffect } from "react";
import AddPointOfSaleForm from "../../components/addPointSaleForm";
import { getPoint } from "./../../redux/pointOfSale/actions";
import { RootState, DefaultApiResult } from "./../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import logo from "../../../images/logo.png";

import { RouteComponentProps } from "react-router-dom";

import "./point.scss";

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const AddPointToSale: React.FC<Props> = ({ match }) => {
  const id: string = match.params.id;

  const dispatch: Dispatch = useDispatch();

  const data = useSelector<RootState, DefaultApiResult>(
    (state) => state.points
  );

  useEffect(() => {
    if (id) {
      dispatch(getPoint(Number(id)));
    }
  }, [dispatch, id]);

  return (
    <article className="add-point-to-sale-page">
      <div className="contanier">
        <div className="row no-gutters">
          <div className="col-xl-8 offset-xl-2 content">
            <div className="img-wrapper">
              <img src={logo} className="App-logo" alt="logo" />
            </div>
            <h3>{id ? "Edytuj punkt sprzedaży" : "Dodaj punkt sprzedaży"}</h3>
            <AddPointOfSaleForm id={id} />
          </div>
        </div>
      </div>
    </article>
  );
};

export default AddPointToSale;
