import React, { FunctionComponent } from "react";
import "./Button.scss";

interface Button {
  /** description for Styleguidist */
  className?: string;
  /** description for Styleguidist */
  loading?: Boolean;

  type?: 'submit' | 'reset' | 'button';

  /** description for Styleguidist */
  disabled?: Boolean;
  /** description for Styleguidist */
  children?: any;
  /** description for Styleguidist */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: FunctionComponent<Button> = ({
  className,
  loading = false,
  disabled = false,
  type = "button",
  children,
  onClick,
}) => {
  return (
    <button
      disabled={!!disabled}
      type={type}
      className={[
        "button",
        className ? className : "",
        loading && "loading",
        disabled && "disabled",
      ].join(" ")}
      onClick={(e) => onClick && onClick(e)}
    >
      {children}
    </button>
  );
};

/*
  render() {
    let className = this.props.className ? this.props.className : "";
    className = this.props.loading ? "loading " + className : className;
    className = this.props.disabled ? "disabled " + className : className;

    let onClick = this.props.onClick || function(e) {};
    return (
      <button className={`button ${className}`} onClick={onClick}>
        {this.props.children}
      </button>
    );
  }
  */

export default Button;
