import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* updateProfileSaga() {
  yield takeLatest(
    ACTION.UPDATE_POFILE_REQUEST,
    createSaga("UPDATE_POFILE", "/profile", "PUT")
  );
}

export function* updatePasswordSaga() {
  yield takeLatest(
    ACTION.UPDATE_PASSWORRD_REQUEST,
    createSaga("UPDATE_PASSWORRD", "/profile/password", "PUT")
  );
}

export function* updateAvatarSaga() {
  yield takeLatest(
    ACTION.UPDATE_AVATAR_REQUEST,
    createSaga("UPDATE_AVATAR", "/profile/avatar", "POST")
  );
}
