import * as ACTION from "./actions";
import { DefaultApiResult, DefaultApiAction } from "../reducers";

export const INIT_STATE = {
  loading: false,
  error: false,
  data: null,
};

export default function reducer(
  state: DefaultApiResult = INIT_STATE,
  action: DefaultApiAction
): DefaultApiResult {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case ACTION.COMPANY_REQUEST:
    case ACTION.ADD_COMPANY_REQUEST:
    case ACTION.GET_COMPANY_INFO_REQUEST:
    case ACTION.UPDATE_COMPANY_REQUEST:
      console.log(action);
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ACTION.ADD_COMPANY_SUCCESS:
    case ACTION.COMPANY_SUCCESS:
    case ACTION.UPDATE_COMPANY_SUCCESS:
      console.log(action);
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...action.payload, added: true },
      };
    case ACTION.GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { success: true, info: action.payload },
      };

    case ACTION.GET_COMPANY_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Nie można znaleźc danych firmy o podanym numerze NIP",
      };

    case ACTION.ADD_COMPANY_FAILURE:
    case ACTION.UPDATE_COMPANY_FAILURE:
      console.log(action);
      return {
        ...state,
        loading: false,
        error: action.payload.errors,
      };
    case ACTION.COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: false,
        data: { error: true },
      };
    default:
      return state;
  }
}
