import * as ACTION from "./actions";
import { DefaultApiResult, DefaultApiAction } from "../reducers";

export const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
};

export default function reducer(
  state: DefaultApiResult = INIT_STATE,
  action: DefaultApiAction
): DefaultApiResult {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case ACTION.GET_POINTS_REQUEST:
    case ACTION.GET_POINT_REQUEST:
    case ACTION.SET_POINT_REQUEST:
    case ACTION.ADD_POINT_REQUEST:
    case ACTION.REMOVE_POINT_REQUEST:
    case ACTION.EDIT_POINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case ACTION.REMOVE_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case ACTION.GET_POINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.content,
      };

    case ACTION.EDIT_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case ACTION.GET_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case ACTION.GET_POINTS_FAILURE:
    case ACTION.GET_POINT_FAILURE:
    case ACTION.SET_POINT_FAILURE:
    case ACTION.ADD_POINT_FAILURE:
    case ACTION.EDIT_POINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.errors,
      };
    case ACTION.REMOVE_POINT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
