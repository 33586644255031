import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* loginSaga() {
  yield takeLatest(
    ACTION.LOGIN_REQUEST,
    createSaga("LOGIN", "/auth/login", "POST")
  );
}

export function* registerSaga() {
  yield takeLatest(
    ACTION.REGISTER_REQUEST,
    createSaga("REGISTER", "/auth/registration/email", "POST")
  );
}
export function* passwordSaga() {
  yield takeLatest(
    ACTION.SET_PASSWORD_REQUEST,
    createSaga("SET_PASSWORD", "/auth/registration/password", "POST")
  );
}

export function* resetPasswordSaga() {
  yield takeLatest(
    ACTION.RESET_PASSWORD_REQUEST,
    createSaga("RESET_PASSWORD", "/auth/password/request_toke", "POST")
  );
}

export function* recommendationSaga() {
  yield takeLatest(
    ACTION.RECOMMENDATION_REQUEST,
    createSaga("RECOMMENDATION", "/partnership_program/recommendation", "POST")
  );
}

export function* getUserInfoSaga() {
  yield takeLatest(
    ACTION.USER_INFO_REQUEST,
    createSaga("USER_INFO", "/profile", "GET")
  );
}

export function* setUserInfoSaga() {
  yield takeLatest(
    ACTION.SET_USER_INFO_REQUEST,
    createSaga("SET_USER_INFO", "/profile", "POST")
  );
}
