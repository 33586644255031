import React, { useEffect } from "react";
import Button from "./../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "./../../redux/reducers";
import { getPoints, removePoint } from "./../../redux/pointOfSale/actions";
import { Link } from "react-router-dom";
import routes from "./../../routes";
import Swal from "sweetalert2";
import { withSwalInstance } from "sweetalert2-react";

import "./list.scss";

interface Props {
  path: string;
}

const PointOfSaleList: React.FC<Props> = ({ path }) => {
  const dispatch: Dispatch = useDispatch();

  const points = useSelector<RootState, DefaultApiResult>(
    (state) => state.points
  );

  const ConfirmDialog = (id: string, name: string) => {
    return withSwalInstance(
      Swal.fire({
        title: "Usuń punkt sprzedaży",
        html: `Czy napewno chcesz usunąć punkt sprzedaży <b>„${name}”</b>?`,
        showCancelButton: true,
        showCloseButton: false,
        confirmButtonText: "Usuń",
        cancelButtonText: "Anuluj",
        customClass: {
          container: "confirm-dialog-wrapper",
          confirmButton: "button submit",
          cancelButton: "button black-squeeze-button",
        },
      }).then((result) => {
        if (result.value) {
          dispatch(removePoint(id));
          dispatch(getPoints());
        }
      })
    );
  };

  useEffect(() => {
    dispatch(getPoints());
  }, [dispatch]);

  return (
    <>
      <div className="list-wrapper">
        {points.data.length > 0 && (
          <table className="point-list">
            <thead>
              <tr>
                <th>Nazwa</th>
                <th>Adres</th>
                <th>Kategoria punktu sprzedaży</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {points.data
                .filter((p) => !p.deleted)
                .map((point: any) => (
                  <tr key={point.id}>
                    <td>{point.name}</td>
                    <td>
                      {point.address?.formattedAddress
                        ? `${point.address.formattedAddress}, ${point.address.postCode} ${point.address.city}`
                        : null}
                    </td>
                    <td>
                      <span className="label">{point.type}</span>
                    </td>
                    <td>
                      <Button
                        className="link"
                        onClick={() => ConfirmDialog(point.id, point.name)}
                      >
                        Usuń
                      </Button>
                      <Link to={`${routes.addpointofSale}/${point.id}`}>
                        Edycja
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <Link
        to={{ pathname: routes.addpointofSale, state: path }}
        className="button submit"
      >
        Dodaj punkt sprzedaży
      </Link>
    </>
  );
};

export default PointOfSaleList;
