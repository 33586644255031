import { DefaultApiAction } from "./reducers";
import { call, put, all } from "redux-saga/effects";
import API from "./../services/api";
import {
  loginSaga,
  registerSaga,
  passwordSaga,
  resetPasswordSaga,
  recommendationSaga,
  getUserInfoSaga,
  setUserInfoSaga,
} from "./auth/sagas";

import {
  addCompanySaga,
  getCompanySaga,
  getCompanyInfoSaga,
  updateCompanySaga,
} from "./company/sagas";

import {
  updateProfileSaga,
  updatePasswordSaga,
  updateAvatarSaga,
} from "./profile/sagas";

import {
  getPointsSaga,
  addPointSaga,
  editPointSaga,
  getPointSaga,
  removePointSaga,
} from "./pointOfSale/sagas";

export function createSaga(prefix: string, endpoint: string, method = "GET") {
  return function*(action: DefaultApiAction) {
    let data: DefaultApiAction = Object.assign({}, action);
    const paramsEndpoint = action.id ? `${endpoint}/${action.id}` : endpoint;

    try {
      const response = yield call(
        API.call,
        paramsEndpoint,
        method,
        action.data
      );
      const responseBody = yield call([response, "json"]);
      if (!response.ok) {
        yield put({
          type: `${prefix}_FAILURE`,
          data,
          errorCode: response.status,
          payload: responseBody,
        });
      } else {
        yield put({ type: `${prefix}_SUCCESS`, payload: responseBody });
      }
    } catch (e) {
      yield put({ type: `${prefix}_FAILURE`, message: e });
    }
  };
}

export default function* rootSaga(getState: any) {
  yield all([
    loginSaga(),
    registerSaga(),
    passwordSaga(),
    resetPasswordSaga(),
    recommendationSaga(),
    updatePasswordSaga(),
    setUserInfoSaga(),
    getUserInfoSaga(),
    addCompanySaga(),
    updateCompanySaga(),
    getCompanySaga(),
    getCompanyInfoSaga(),
    updateAvatarSaga(),
    removePointSaga(),
    getPointSaga(),
    editPointSaga(),
    getPointsSaga(),
    addPointSaga(),
    updateProfileSaga(),
  ]);
}
