import * as ACTION from "./actions";
import { AuthApiResult, DefaultApiAction } from "../reducers";

export const INIT_STATE = {
  loading: false,
  error: false,
  logged: false,
  data: null,
  user: null,
  token: null,
};

export default function reducer(
  state: AuthApiResult = INIT_STATE,
  action: DefaultApiAction
): AuthApiResult {
  if (state === undefined) {
    return INIT_STATE;
  }
  switch (action.type) {
    case ACTION.UPDATE_POFILE_REQUEST:
    case ACTION.UPDATE_PASSWORRD_REQUEST:
    case ACTION.UPDATE_AVATAR_REQUEST:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case ACTION.UPDATE_POFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
        user: action.payload,
      };
    case ACTION.UPDATE_PASSWORRD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case ACTION.UPDATE_AVATAR_SUCCESS:
      console.log(action);
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ACTION.UPDATE_POFILE_FAILURE:
    case ACTION.UPDATE_PASSWORRD_FAILURE:
    case ACTION.UPDATE_AVATAR_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload.errors,
        logged: false,
      };
    default:
      return state;
  }
}
