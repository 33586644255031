import auth from "./auth/reducer";
import points from "./pointOfSale/reducer";
import company from "./company/reducer";
import profile from "./profile/reducer";
export interface RootState {
  data: DefaultApiResult;
  points: DefaultApiResult;
  company: DefaultApiResult;
  auth: AuthApiResult;
  profile: AuthApiResult;
}

export interface DefaultApiResult {
  loading: boolean;
  error: any;
  data: any;
}

export interface AuthApiResult {
  loading: boolean;
  logged: boolean;
  error?: any;
  data?: any;
  user?: any;
  token: string | null;
}

export interface DefaultApiAction {
  id?: string | number;
  type: string;
  error?: any;
  payload?: any;
  data?: DefaultApiAction;
}

export default {
  auth,
  points,
  company,
  profile,
};
