import React, { useEffect, useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikErrors,
  FieldProps,
  FormikProps,
} from "formik";
import { useHistory } from "react-router-dom";
import Button from "./../Button/Button";
import UserAvatar from "./../../../images/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  RootState,
  DefaultApiResult,
  AuthApiResult,
} from "./../../redux/reducers";
import { updateCompany } from "./../../redux/company/actions";
import { updateProfile, updateAvatar } from "./../../redux/profile/actions";
import displayMessage from "../../helpers/displayMessage";

import "./settings-form.scss";

interface Address {
  city: string;
  street: string;
  buildingNumber: string;
  apartmentNumber: string;
  postCode: string;
  county: string;
}

interface MyFormValues {
  nip?: string;
  name?: string;
  email: string;
  firstName: string;
  lastName: string;
  headquarterAddress: Address;
  useHeadquarterAddressOnly: boolean;
  correspondenceAddress: Address | undefined;
  bankAccountNumber: number | string;
  vatTaxesPayer: boolean | null;
  phoneNumber: string;
  confirmData: boolean;
  approvalRegulations: boolean;
}

const initialValues: MyFormValues = {
  nip: "",
  name: "",
  email: "",
  firstName: "",
  lastName: "",
  headquarterAddress: {
    city: "",
    street: "",
    buildingNumber: "",
    apartmentNumber: "",
    postCode: "",
    county: "",
  },
  useHeadquarterAddressOnly: false,
  correspondenceAddress: {
    city: "",
    street: "",
    buildingNumber: "",
    apartmentNumber: "",
    postCode: "",
    county: "",
  },
  bankAccountNumber: "",
  vatTaxesPayer: false,
  confirmData: false,
  phoneNumber: "",
  approvalRegulations: false,
};

const SettingsForm: React.FC = () => {
  const [correAddress, setCorreAddress] = useState(false);
  const [vatcheckbox, setVatcheckbox] = useState(false);
  const [avatarFile, setAvatarFile] = useState(undefined);
  const [initialValue, setInitialValue] = useState(initialValues);
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();

  const auth = useSelector<RootState, AuthApiResult>((state) => state.auth);

  const data = useSelector<RootState, DefaultApiResult>(
    (state) => state.company
  );

  const onFileChange = async (e) => {
    // const file = e.target.files[0];
    // var reader = new FileReader();
    // var url = reader.readAsDataURL(file);

    // reader.onloadend = function(e) {
    //   console.log(reader.result);
    //   // setAvatarFile(reader.result);
    // };

    // console.log(url);
    dispatch(updateAvatar(e.target.files[0]));
  };

  useEffect(() => {
    if (auth.user) {
      setInitialValue({
        ...initialValue,
        nip: auth.user.company.nip,
        name: auth.user.company.name,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        email: auth.user.email,
        phoneNumber: auth.user.company.phoneNumber,
        bankAccountNumber:
          auth.user.company.bankAccountNumber != null
            ? auth.user.company.bankAccountNumber
            : "",
        vatTaxesPayer: auth.user.company.vatTaxesPayer,
        headquarterAddress: {
          ...initialValue.headquarterAddress,
          city: auth.user.company.headquarterAddress.city,
          county: auth.user.company.headquarterAddress.county,
          postCode: auth.user.company.headquarterAddress.postCode,
          street: auth.user.company.headquarterAddress.street,
          apartmentNumber: auth.user.company.headquarterAddress.apartmentNumber,
          buildingNumber: auth.user.company.headquarterAddress.buildingNumber,
        },
      });
      setCorreAddress(auth.user.company.useHeadquarterAddressOnly);
      setVatcheckbox(
        auth.user.company.vatTaxesPayer != null
          ? auth.user.company.vatTaxesPayer
          : false
      );
      setAvatarFile(auth.user.avatar);
    }
    if (data.error) {
      displayMessage(data.error, "error");
    }
    console.log(avatarFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, history]);
  return (
    <div className="company-form settings">
      <Formik
        enableReinitialize
        initialValues={initialValue}
        validate={(values: MyFormValues) => {
          const errors: FormikErrors<MyFormValues> = {};
          return errors;
        }}
        onSubmit={(values, actions) => {
          dispatch(
            updateCompany(auth.user.company.id, {
              ...values,
              useHeadquarterAddressOnly: correAddress,
              vatTaxesPayer: vatcheckbox,
            })
          );

          dispatch(updateProfile(values));
          actions.setSubmitting(false);
        }}
      >
        {(props: FormikProps<MyFormValues>) => (
          <Form>
            <div className="row">
              <div className="col-xl-12">
                <h4>Podstawowe informacje</h4>
              </div>
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-lg-6 col-xl-4">
                    <Field name="firstName">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Imię"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-xl-4">
                    <Field name="lastName">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Nazwisko"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-12 col-xl-4">
                    <fieldset>
                      <label className="upload-avatar">
                        <img
                          src={avatarFile ? avatarFile : UserAvatar}
                          alt="avatar"
                        />
                        <div>
                          <h4>{initialValue.firstName}</h4>
                          Zmień zdjęcie&nbsp;>
                          <input
                            type="file"
                            onChange={(e: any) =>
                              // console.log(
                              //   ,
                              //   e.value
                              // )
                              onFileChange(e)
                            }
                          />
                        </div>
                      </label>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-xl-8">
                <div className="row">
                  <div className="col-xl-12">
                    <h4>Adres siedziby</h4>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="headquarterAddress.street">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="ulica"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="headquarterAddress.buildingNumber">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="numer mieszkania, lokalu"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="headquarterAddress.city">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Miejscowość"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="headquarterAddress.apartmentNumber">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Lokal"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="headquarterAddress.postCode">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Kod pocztowy"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="headquarterAddress.county">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Powiat"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-12">
                    <h4>
                      Adres korespondencyjny
                      <>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={correAddress}
                            onChange={() => setCorreAddress(!correAddress)}
                          />
                          <div className="checkmark"></div>
                          Taki sam jak siedziby
                        </label>
                      </>
                    </h4>
                  </div>
                  {!correAddress && (
                    <>
                      <div className="col-lg-6 col-xl-6">
                        <Field name="correspondenceAddress.street">
                          {({ field, meta }: FieldProps) => (
                            <fieldset>
                              <input
                                type="text"
                                placeholder="ulica"
                                className={meta.error && "invalid"}
                                {...field}
                              />
                              {meta.touched && meta.error && (
                                <div className="error">{meta.error}</div>
                              )}
                            </fieldset>
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-6 col-xl-6">
                        <Field name="correspondenceAddress.buildingNumber">
                          {({ field, meta }: FieldProps) => (
                            <fieldset>
                              <input
                                type="text"
                                placeholder="numer mieszkania, lokalu"
                                className={meta.error && "invalid"}
                                {...field}
                              />
                              {meta.touched && meta.error && (
                                <div className="error">{meta.error}</div>
                              )}
                            </fieldset>
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-6 col-xl-6">
                        <Field name="correspondenceAddress.city">
                          {({ field, meta }: FieldProps) => (
                            <fieldset>
                              <input
                                type="text"
                                placeholder="Miejscowość"
                                className={meta.error && "invalid"}
                                {...field}
                              />
                              {meta.touched && meta.error && (
                                <div className="error">{meta.error}</div>
                              )}
                            </fieldset>
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-6 col-xl-6">
                        <Field name="correspondenceAddress.apartmentNumber">
                          {({ field, meta }: FieldProps) => (
                            <fieldset>
                              <input
                                type="text"
                                placeholder="Lokal"
                                className={meta.error && "invalid"}
                                {...field}
                              />
                              {meta.touched && meta.error && (
                                <div className="error">{meta.error}</div>
                              )}
                            </fieldset>
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-6 col-xl-6">
                        <Field name="correspondenceAddress.postCode">
                          {({ field, meta }: FieldProps) => (
                            <fieldset>
                              <input
                                type="text"
                                placeholder="Kod pocztowy"
                                className={meta.error && "invalid"}
                                {...field}
                              />
                              {meta.touched && meta.error && (
                                <div className="error">{meta.error}</div>
                              )}
                            </fieldset>
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-6 col-xl-6">
                        <Field name="correspondenceAddress.county">
                          {({ field, meta }: FieldProps) => (
                            <fieldset>
                              <input
                                type="text"
                                placeholder="Powiat"
                                className={meta.error && "invalid"}
                                {...field}
                              />
                              {meta.touched && meta.error && (
                                <div className="error">{meta.error}</div>
                              )}
                            </fieldset>
                          )}
                        </Field>
                      </div>
                    </>
                  )}
                  <div className="col-xl-12">
                    <h4>Dane kontaktowe</h4>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="phoneNumber">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Numer telefonu"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <Field name="email">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Adres e-mail"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-12">
                    <h4>
                      Dane do rozliczeń{" "}
                      <span>
                        (Dodaj numer konta, który posłuży nam do wypłaty
                        dodatkowego wynagrodzenia za udział w konkursach)
                      </span>
                    </h4>
                  </div>
                  <div className="col-xl-6">
                    <Field name="bankAccountNumber">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Numer konta bankowego"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-6">
                    <fieldset>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={vatcheckbox}
                          onChange={() => setVatcheckbox(!vatcheckbox)}
                        />
                        <div className="checkmark"></div>
                        Jestem płatnikiem podatku VAT 23%
                      </label>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div className="row footer">
              <div className="col-xl-12">
                <Button
                  className={data.loading ? "submit loading" : "submit"}
                  type="submit"
                >
                  Zapisz zmiany
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SettingsForm;
