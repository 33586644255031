import React, { useEffect, ReactNode } from "react";
import { Formik, Form, Field, FormikErrors, FieldProps } from "formik";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState, AuthApiResult } from "../../redux/reducers";
import { setPassword } from "../../redux/auth/actions";
import useLocalStorage from "./../../hooks/useLocalStorage";
import displayMessage from "../../helpers/displayMessage";
import { useHistory } from "react-router-dom";
interface MyFormValues {
  password: string;
  confirmPassword: string;
}

interface ComponentProps {
  uuid: ReactNode;
}

const PasswordForm: React.FC<ComponentProps> = ({ uuid }) => {
  const initialValues: MyFormValues = {
    password: "",
    confirmPassword: "",
  };
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const [, setItem] = useLocalStorage("token", "");

  const auth = useSelector<RootState, AuthApiResult>((state) => state.auth);

  useEffect(() => {
    if (auth.token && auth.logged) {
      setItem(auth.token);
      history.push("/");
    }
    if (auth.error) {
      displayMessage(auth.error, "error");
    }
  }, [auth, history, setItem]);

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: MyFormValues) => {
        const errors: FormikErrors<MyFormValues> = {};
        if (!values.password) {
          errors.password = "To pole jest wymagane";
        } else if (values.password.length < 8) {
          errors.password = "Hasło musi zawierać co najmniej 8 znaków";
        }
        if (!values.confirmPassword) {
          errors.confirmPassword = "To pole jest wymagane";
        } else if (values.confirmPassword.length < 8) {
          errors.confirmPassword = "Hasło musi zawierać co najmniej 8 znaków";
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = "Hasła muszą być identyczne";
        }
        return errors;
      }}
      onSubmit={(values, actions) => {
        dispatch(setPassword({ ...values, uuid }));
        actions.setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <Field name="password">
            {({ field, meta }: FieldProps) => (
              <fieldset>
                <input
                  type="password"
                  placeholder="Wprowadź hasło"
                  className={meta.error && "invalid"}
                  {...field}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </fieldset>
            )}
          </Field>
          <Field name="confirmPassword">
            {({ field, meta }: FieldProps) => (
              <fieldset>
                <input
                  type="password"
                  placeholder="Powtórz hasło"
                  className={meta.error && "invalid"}
                  {...field}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </fieldset>
            )}
          </Field>
          <div>
            <Button
              className={auth.loading ? "submit loading" : "submit"}
              type="submit"
            >
              Ustaw hasło
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordForm;
