import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FormikErrors, FieldProps } from "formik";
import Button from "./../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { eventBus } from "./../../helpers/eventBus";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "./../../redux/reducers";
import {
  addPoint,
  editPoint,
  EDIT_POINT_SUCCESS,
  ADD_POINT_SUCCESS,
} from "./../../redux/pointOfSale/actions";
import Select from "./../Select";
import displayMessage from "../../helpers/displayMessage";

interface Address {
  city: string;
  street: string;
  buildingNumber: string;
  apartmentNumber: string;
  postCode: string;
  county: string;
}

interface MyFormValues {
  type: string;
  name: string;
  address: Address;
  useHeadquarterAddress: boolean;
}

interface FormProps {
  id?: string;
}

const initialValues: MyFormValues = {
  name: "",
  useHeadquarterAddress: false,
  address: {
    city: "",
    street: "",
    buildingNumber: "",
    apartmentNumber: "",
    postCode: "",
    county: "",
  },
  type: "",
};

const options = [
  { value: "1", label: "Sklep wielobranżowy" },
  { value: "2", label: "Sklep monopolowy" },
  { value: "3", label: "Kiosk" },
  { value: "4", label: "Salon prasowy" },
  { value: "5", label: "Stacja paliw" },
  { value: "6", label: "Sklep spożywczy" },
];

const AddPointOfSaleForm: React.FC<FormProps> = ({ id }) => {
  const [correAddress, setCorreAddress] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();
  const [item] = useLocalStorage("path", "");
  const data = useSelector<RootState, DefaultApiResult>(
    (state) => state.points
  );

  useEffect(() => {
    if (id) {
      setFormValues({
        ...formValues,
        name: data.data.name,
        useHeadquarterAddress: data.data.useHeadquarterAddress,
        address: {
          city: data.data?.address?.city,
          street: data.data?.address?.street,
          buildingNumber: data.data?.address?.buildingNumber,
          apartmentNumber: data.data?.address?.apartmentNumber,
          postCode: data.data?.address?.postCode,
          county: data.data?.address?.county,
        },
        type: data.data.type,
      });

      setCorreAddress(data.data.useHeadquarterAddress);
    }
    eventBus(EDIT_POINT_SUCCESS).then((payload: any) => {
      if (payload) {
        history.push(item.substring(1, item.length - 1));
      }
    });
    eventBus(ADD_POINT_SUCCESS).then((payload: any) => {
      if (payload) {
        history.push(item.substring(1, item.length - 1));
      }
    });
    if (data.error) {
      displayMessage(data.error, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, history, item]);

  return (
    <div className="company-form">
      <Formik
        enableReinitialize
        initialValues={formValues}
        validate={(values: MyFormValues) => {
          const errors: FormikErrors<MyFormValues> = {};
          if (!values.name) {
            errors.name = "To pole jest wymagane";
          }
          if (!values.type) {
            errors.type = "To pole jest wymagane";
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          if (id) {
            dispatch(
              editPoint(Number(id), {
                ...formValues,
                id: id,
                useHeadquarterAddress: correAddress,
              })
            );
          } else {
            dispatch(
              addPoint({ ...formValues, useHeadquarterAddress: correAddress })
            );
          }
          actions.setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <div className="row">
              <div className="col-xl-6">
                <fieldset>
                  <input
                    type="text"
                    placeholder="Nazwa"
                    value={formValues.name}
                    onChange={(e) =>
                      setFormValues({ ...formValues, name: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <div className="col-xl-6 checkbox-col">
                <fieldset>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={correAddress}
                      onChange={() => setCorreAddress(!correAddress)}
                    />
                    <div className="checkmark"></div>
                    Taki sam jak siedziby
                  </label>
                </fieldset>
              </div>
              {!correAddress && (
                <>
                  <div className="col-xl-6">
                    <fieldset>
                      <input
                        type="text"
                        placeholder="ulica"
                        value={formValues.address.street}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            address: {
                              ...formValues.address,
                              street: e.target.value,
                            },
                          });
                        }}
                      />
                    </fieldset>
                  </div>
                  <div className="col-xl-3">
                    <fieldset>
                      <input
                        type="text"
                        placeholder="numer mieszkania, lokalu"
                        value={formValues.address.buildingNumber}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            address: {
                              ...formValues.address,
                              buildingNumber: e.target.value,
                            },
                          });
                        }}
                      />
                    </fieldset>
                  </div>
                  <div className="col-xl-3">
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Lokal"
                        value={formValues.address.apartmentNumber}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            address: {
                              ...formValues.address,
                              apartmentNumber: e.target.value,
                            },
                          });
                        }}
                      />
                    </fieldset>
                  </div>
                  <div className="col-xl-6">
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Miejscowość"
                        value={formValues.address.city}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            address: {
                              ...formValues.address,
                              city: e.target.value,
                            },
                          });
                        }}
                      />
                    </fieldset>
                  </div>

                  <div className="col-xl-6">
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Kod pocztowy"
                        value={formValues.address.postCode}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            address: {
                              ...formValues.address,
                              postCode: e.target.value,
                            },
                          });
                        }}
                      />
                    </fieldset>
                  </div>
                  <div className="col-xl-6">
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Powiat"
                        value={formValues.address.county}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            address: {
                              ...formValues.address,
                              county: e.target.value,
                            },
                          });
                        }}
                      />
                    </fieldset>
                  </div>
                </>
              )}
              <div className="col-xl-6">
                <Field name="type">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <Select
                        options={options}
                        name="type"
                        defaultInputValue={
                          id &&
                          options.filter(
                            (option) => option.value === formValues.type
                          )
                        }
                        onChange={(option: any) =>
                          setFormValues({ ...formValues, type: option.value })
                        }
                        placeholder="Rodzaj punktu sprzedaży:"
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
            </div>
            <div className="row footer">
              <div className="col-xl-6 offset-xl-6">
                <Button
                  className={data.loading ? "submit loading" : "submit"}
                  type="submit"
                >
                  {id ? "Zaktualizuj punkt sprzedaży" : "Dodaj punkt sprzedaży"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPointOfSaleForm;
