import React from "react";
import "./decoration-item.scss";

interface Props {
  Cdate: string;
  title: string;
  icon?: string;
  Cnumber?: string;
  Cfile?: string;
}

const DecorationListItem: React.FC<Props> = ({
  Cdate,
  title,
  icon,
  Cnumber,
  Cfile,
}) => {
  return (
    <div className="decoration-item">
      <div className="item-row">
        <div className="mark">
          {icon && <img src={icon} className="icon" alt="file icon" />}
          {Cnumber && <span>{Cnumber}</span>}
        </div>
        <div>
          <span className="date-subtitle">{Cdate}</span>
          <h4>{title}</h4>
        </div>
      </div>
      {Cfile && (
        <div className="item-row">
          <a href={Cfile}>Podgląd</a>
          <a href={Cfile} download>
            Pobierz
          </a>
        </div>
      )}
    </div>
  );
};

export default DecorationListItem;
