import React, { useEffect } from "react";
import { Formik, Form, Field, FormikErrors } from "formik";
import Button from "./../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState, AuthApiResult } from "./../../redux/reducers";
import { register } from "./../../redux/auth/actions";
import displayMessage from "../../helpers/displayMessage";

interface MyFormValues {
  email: string;
}

const RegisterForm: React.FC = () => {
  const initialValues: MyFormValues = {
    email: "",
  };

  const dispatch: Dispatch = useDispatch();

  const auth = useSelector<RootState, AuthApiResult>((state) => state.auth);

  useEffect(() => {
    if (auth.data && auth.data.success) {
      displayMessage("Link aktywacyjny został wysłany na e-mail", "success");
    }
    if (auth.error) {
      displayMessage(auth.error, "error");
    }
  }, [auth]);

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: MyFormValues) => {
        const errors: FormikErrors<MyFormValues> = {};
        if (!values.email) {
          errors.email = "To pole jest wymagane";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Nieprawidłowy adres email";
        }
        return errors;
      }}
      onSubmit={(values, actions) => {
        dispatch(register(values));
        actions.setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <Field name="email">
            {({ field, meta }: { field: any; meta: any }) => (
              <fieldset>
                <input
                  type="email"
                  placeholder="Adres mailowy"
                  className={meta.error && "invalid"}
                  {...field}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </fieldset>
            )}
          </Field>
          <div>
            <Button
              className={auth.loading ? "submit loading" : "submit"}
              type="submit"
            >
              Zarejestruj się
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
