export const GET_POINTS_REQUEST = "GET_POINTS_REQUEST";
export const GET_POINTS_SUCCESS = "GET_POINTS_SUCCESS";
export const GET_POINTS_FAILURE = "GET_POINTS_FAILURE";
export const GET_POINT_REQUEST = "GET_POINT_REQUEST";
export const GET_POINT_SUCCESS = "GET_POINT_SUCCESS";
export const GET_POINT_FAILURE = "GET_POINT_FAILURE";
export const SET_POINT_REQUEST = "SET_POINT_REQUEST";
export const SET_POINT_SUCCESS = "SET_POINT_SUCCESS";
export const SET_POINT_FAILURE = "SET_POINT_FAILURE";
export const ADD_POINT_REQUEST = "ADD_POINT_REQUEST";
export const ADD_POINT_SUCCESS = "ADD_POINT_SUCCESS";
export const ADD_POINT_FAILURE = "ADD_POINT_FAILURE";
export const REMOVE_POINT_REQUEST = "REMOVE_POINT_REQUEST";
export const REMOVE_POINT_SUCCESS = "REMOVE_POINT_SUCCESS";
export const REMOVE_POINT_FAILURE = "REMOVE_POINT_FAILURE";
export const EDIT_POINT_REQUEST = "EDIT_POINT_REQUEST";
export const EDIT_POINT_SUCCESS = "EDIT_POINT_SUCCESS";
export const EDIT_POINT_FAILURE = "EDIT_POINT_FAILURE";

export const getPoints = () => {
  return {
    method: "GET",
    type: GET_POINTS_REQUEST,
  };
};

export const getPoint = (id: number) => {
  return {
    method: "GET",
    type: GET_POINT_REQUEST,
    id,
  };
};

export const setPoint = (data?: object) => {
  return {
    method: "POST",
    type: SET_POINT_REQUEST,
    data,
  };
};

export const addPoint = (data?: object) => {
  return {
    method: "POST",
    type: ADD_POINT_REQUEST,
    data,
  };
};

export const editPoint = (id: number, data?: object) => {
  return {
    method: "PUT",
    type: EDIT_POINT_REQUEST,
    data,
    id,
  };
};

export const removePoint = (id?: string) => {
  return {
    method: "DELETE",
    type: REMOVE_POINT_REQUEST,
    id,
  };
};
