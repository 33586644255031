import { takeLatest } from "redux-saga/effects";
import * as ACTION from "./actions";
import { createSaga } from "../sagas";

export function* getPointsSaga() {
  yield takeLatest(
    ACTION.GET_POINTS_REQUEST,
    createSaga("GET_POINTS", "/pos", "GET")
  );
}

export function* getPointSaga() {
  yield takeLatest(
    ACTION.GET_POINT_REQUEST,
    createSaga("GET_POINT", "/pos", "GET")
  );
}

export function* addPointSaga() {
  yield takeLatest(
    ACTION.ADD_POINT_REQUEST,
    createSaga("ADD_POINT", "/pos", "POST")
  );
}

export function* editPointSaga() {
  yield takeLatest(
    ACTION.EDIT_POINT_REQUEST,
    createSaga("EDIT_POINT", "/pos", "PUT")
  );
}

export function* removePointSaga() {
  yield takeLatest(
    ACTION.REMOVE_POINT_REQUEST,
    createSaga("REMOVE_POINT", `/pos`, "DELETE")
  );
}
