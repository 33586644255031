export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RECOMMENDATION_REQUEST = "RECOMMENDATION_REQUEST";
export const RECOMMENDATION_SUCCESS = "RECOMMENDATION_SUCCESS";
export const RECOMMENDATION_FAILURE = "RECOMMENDATION_FAILURE";
export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAILURE = "USER_INFO_FAILURE";
export const SET_USER_INFO_REQUEST = "SET_USER_INFO_REQUEST";
export const SET_USER_INFO_SUCCESS = "SET_USER_INFO_SUCCESS";
export const SET_USER_INFO_FAILURE = "SET_USER_INFO_FAILURE";

export const login = (data?: object) => {
  return {
    method: "POST",
    type: LOGIN_REQUEST,
    data,
  };
};

export const register = (data?: object) => {
  return {
    method: "POST",
    type: REGISTER_REQUEST,
    data,
  };
};

export const resetPassword = (data?: object) => {
  return {
    method: "POST",
    type: RESET_PASSWORD_REQUEST,
    data,
  };
};

export const setPassword = (data?: object) => {
  return {
    method: "POST",
    type: SET_PASSWORD_REQUEST,
    data,
  };
};

export const recommendation = (data?: object) => {
  return {
    method: "POST",
    data,
    type: RECOMMENDATION_REQUEST,
  };
};

export const getUserInfo = () => {
  return {
    type: USER_INFO_REQUEST,
  };
};

export const setUserInfo = (data?: object) => {
  return {
    type: SET_USER_INFO_REQUEST,
    method: "POST",
    data,
  };
};
