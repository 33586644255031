import React, { useEffect } from "react";
import withMainLayout from "./../../layouts/index";
import PointOfSaleList from "../../components/pointOfSaleList";
import SettingsForm from "../../components/settingsForm";
import useLocalStorage from "../../hooks/useLocalStorage";
import routes from "../../routes";
import ChangePasswordForm from "../../components/changePasswordForm";

import "./settings.scss";

const Settings: React.FC = () => {
  const [item, setItem] = useLocalStorage("path", "");

  useEffect(() => {
    setItem(JSON.stringify(routes.settings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className="settings-page">
      <div className="row no-gutters">
        <div className="col-12">
          <div className="header-page">
            <h2>Ustawienia</h2>
          </div>
        </div>
        <div className="col-xl-12">
          <SettingsForm />
        </div>
        <div className="col-xl-8">
          <ChangePasswordForm />
        </div>
        <div className="col-xl-8">
          <h3>Lista zgłoszonych punktów sprzedaży</h3>
          <PointOfSaleList path={routes.settings} />
        </div>
      </div>
    </article>
  );
};

export default withMainLayout(Settings);
