import React from "react";
import "./list.scss";

interface link {
  link: string;
  title: string;
}

interface PropsList {
  list: Array<link>;
}

const InfoList: React.FC<PropsList> = ({ list }) => {
  return (
    <ul className="info-list">
      {list.map((item, index) => (
        <li key={index}>
          <a href={item.link} target="_blank">
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default InfoList;
