import React, { useState } from "react";
import logo from "../../../images/logo.png";
import UserAvatar from "./../../../images/user.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  DefaultApiResult,
  AuthApiResult,
} from "./../../redux/reducers";
import Button from "../Button/Button";
import { NavLink } from "react-router-dom";
import Icon from "./../Icon";

import routes from "./../../routes";

import "./sidebar.scss";

interface menuItem {
  link: string;
  label: string;
  icon: string;
}

interface avatarProps {
  avatarFile?: string;
  name?: string;
}

const MenuItem = ({ link, label, icon }: menuItem) => (
  <NavLink to={link} activeClassName="active">
    <Icon name={icon} /> <span>{label}</span>
  </NavLink>
);

const menu: Array<menuItem> = [
  { link: routes.home, label: "Panel główny", icon: "speed" },
  // { link: routes.company, label: "Promocje i sklep", icon: "basket" },
  // { link: routes.company, label: "Konkursy", icon: "shield" },
  // { link: routes.company, label: "Szkolenia", icon: "certificate" },
  // { link: routes.company, label: "Opinie", icon: "clipboard" },
  // { link: routes.company, label: "Planogram", icon: "edit" },
  // { link: routes.company, label: "Rozliczenia", icon: "wallet" },
  // { link: routes.company, label: "Komunikaty", icon: "promote" },
  // { link: routes.company, label: "Baza wiedzy", icon: "graduation" },
  // { link: routes.company, label: "Materiały do pobrania", icon: "page" },
  { link: routes.settings, label: "Ustawienia", icon: "settings" },
  // { link: routes.company, label: "Pracownicy", icon: "users" },
  // { link: routes.company, label: "Twoi klienci", icon: "customer" },
];

const SidebarHeaderMobile: React.FC<avatarProps> = ({ avatarFile, name }) => (
  <div className="mobile-header">
    <img src={avatarFile ? avatarFile : UserAvatar} alt="avatar" />{" "}
    <span>
      Cześć, <b>{name}</b>
    </span>
  </div>
);

const Sidebar: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const auth = useSelector<RootState, AuthApiResult>((state) => state.auth);

  return (
    <nav className={activeMenu ? "sidebar active" : "sidebar"}>
      <div className="sidebar--header">
        {activeMenu ? (
          <SidebarHeaderMobile
            avatarFile={auth.user.avatar}
            name={auth.user.firstName}
          />
        ) : (
          <img src={logo} className="App-logo" alt="logo" />
        )}
        <Button
          className={
            activeMenu ? "button hamburger active" : "button hamburger"
          }
          onClick={() => setActiveMenu(!activeMenu)}
        >
          <span></span>
          <span></span>
          <span></span>
        </Button>
      </div>
      <ul className="sidebar--menu">
        {menu.map((item, index) => (
          <li key={index}>
            <MenuItem link={item.link} label={item.label} icon={item.icon} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
