import React, { useState, useEffect } from "react";
import logo from "../../../images/logo.png";
import CompanyForm from "../../components/companyForm";
import PointOfSaleList from "../../components/pointOfSaleList";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
  RootState,
  DefaultApiResult,
  AuthApiResult,
} from "./../../redux/reducers";
import routes from "./../../routes";

import "./company.scss";

const Company: React.FC = () => {
  const [company, setCompany] = useState(true);

  const data = useSelector<RootState, DefaultApiResult>(
    (state) => state.company
  );
  const [item, setItem] = useLocalStorage("path", "");
  const auth = useSelector<RootState, AuthApiResult>((state) => state.auth);

  const points = useSelector<RootState, DefaultApiResult>(
    (state) => state.points
  );

  useEffect(() => {
    setItem(JSON.stringify(routes.company));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (auth.user != null && auth.user?.company && !auth.loading) {
      setCompany(false);
    }
  }, [auth, data, company]);
  return (
    <article className="company-page">
      <div className="row no-gutters">
        <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 content">
          <div className="img-wrapper">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          {company ? (
            <>
              <h3>Cześć! Super, że do nas dołączasz</h3>
              <CompanyForm setCompany={(e) => setCompany(e)} />{" "}
            </>
          ) : (
            <div className="points-wrapper">
              <h3>Witaj w świecie SKYROCKET!</h3>
              <p>
                Ostatnie kroki dzielą Cię od założenia konta. Prosimy o
                uzupełnienie brakujących danych oraz potwierdzenie danych <br />{" "}
                do rozliczeń. Zgodnie z tymi danymi będziemy wystawiać faktury
                oraz wypłacać zgromadzoną premie.
              </p>
              <p> Upewnij się, że wszystkie wprowadzane dane są poprawne.</p>
              <h4>Lista zgłoszonych punktów sprzedaży</h4>
              <PointOfSaleList path={routes.company} />
              {points.data.length > 0 && (
                <div className="actions-wrapper">
                  <Link to={routes.survey} className="button submit">
                    Zapisz i przejdź dalej
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default Company;
