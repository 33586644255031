import React from "react";
import withMainLayout from "./../../layouts/index";
import SectionHeader from "./../../components/sectionHeader";
import InfoList from "../../components/infoList";
import ContestImage from "./../../../images/contest.png";
import DecorationListItem from "../../components/decorationListItem";

import "./dashboard.scss";

const links = [
  { link: "#1", title: "Regulamin konkursu" },
  { link: "#2", title: "Regulamin programu partnerskiego" },
];

const Dashboard: React.FC = () => {
  return (
    <article className="dashboard-page">
      <div className="row no-gutters">
        <div className="col-12">
          <div className="header-page">
            <h2>Panel główny</h2>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="img-wrapper">
            <img src={ContestImage} alt="contest-img" />
          </div>
          <SectionHeader title="Postęp w konkursie" />
          <ul className="contest-list">
            <li>
              <DecorationListItem
                title="To jest twoja aktualna pozycja w rankingu"
                Cdate="RANKING"
                Cnumber="21"
              />
            </li>
            <li>
              <DecorationListItem
                title="ilość założonych kont z twojego polecenia"
                Cdate="POLECENIA"
                Cnumber="53"
              />
            </li>
          </ul>
          <SectionHeader title="Przydatne linki" />
          <InfoList list={links} />
        </div>
      </div>
    </article>
  );
};

export default withMainLayout(Dashboard);
