/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import "./../scss/app.scss";
import { RootState, AuthApiResult } from "./redux/reducers";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "./routes";
import { getUserInfo } from "./redux/auth/actions";
import { getPoints } from "./redux/pointOfSale/actions";
import useLocalStorage from "./hooks/useLocalStorage";

import Login from "./pages/login";
import Register from "./pages/register";
import Dashboard from "./pages/dashboard";
import Home from "./pages/home";
import Settings from "./pages/settings";
import AddPointToSale from "./pages/addPointToSale";
import Company from "./pages/campany";
import Survey from "./pages/survey/index";

const App: React.FC = () => {
  const auth = useSelector<RootState, AuthApiResult>((state) => state.auth);
  const dispatch: Dispatch = useDispatch();
  const [token] = useLocalStorage("token", "");

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
      dispatch(getPoints());
    }
  }, [token]);

  const PrivateRoute = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={(props) =>
        auth.token || token ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.login} />
        )
      }
    />
  );

  const {
    login,
    register,
    setPassword,
    home,
    settings,
    company,
    addpointofSale,
    editpointofSale,
    survey,
  } = routes;

  return (
    <div className="App">
      <Switch>
        <Route path={login} exact component={Login} />
        <Route path={register} exact component={Register} />
        <Route path={setPassword} exact component={Register} />
        <PrivateRoute path={home} exact component={Dashboard} />
        <PrivateRoute path="/" exact component={Home} />
        <PrivateRoute path={settings} exact component={Settings} />
        <PrivateRoute path={company} exact component={Company} />
        <PrivateRoute path={survey} exact component={Survey} />
        <PrivateRoute path={addpointofSale} exact component={AddPointToSale} />
        <PrivateRoute path={editpointofSale} exact component={AddPointToSale} />
      </Switch>
    </div>
  );
};

export default App;
