const routes = {
  home: "/dashboard",
  login: "/login",
  register: "/register",
  resetPassword: "/reset-password",
  setPassword: "/register/:uuid",
  company: "/company",
  settings: "/settings",
  addpointofSale: "/point-of-sale",
  editpointofSale: "/point-of-sale/:id",
  survey: "/survey",
};

export default routes;
