import React from "react";
import { Formik, Form, Field, FormikErrors, FieldProps } from "formik";

import Button from "./../Button/Button";

interface MyFormValues {
  email: string;
  emailconfirmation: string;
  phone: string;
  nip: string;
}

interface Props {
  submitHandler: (data: MyFormValues) => void;
  cancelHandler: () => void;
}

const initialValues: MyFormValues = {
  email: "",
  emailconfirmation: "",
  phone: "",
  nip: "",
};

const ResetForm: React.FC<Props> = ({ submitHandler, cancelHandler }) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={(values: MyFormValues) => {
        const errors: FormikErrors<MyFormValues> = {};
        if (!values.email) {
          errors.email = "To pole jest wymagane";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Nieprawidłowy adres email";
        }
        if (values.emailconfirmation !== values.email) {
          errors.emailconfirmation = "Podałeś niepoprawny adres";
        }
        if (!values.emailconfirmation) {
          errors.emailconfirmation = "To pole jest wymagane";
        }
        if (!values.phone) {
          errors.phone = "To pole jest wymagane";
        }
        if (!values.nip) {
          errors.nip = "To pole jest wymagane";
        }

        return errors;
      }}
      onSubmit={(values, actions) => {
        submitHandler(values);
        actions.setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <div className="row">
            <div className="col-xl-6">
              <Field name="email">
                {({ field, meta }: FieldProps) => (
                  <fieldset>
                    <input
                      type="text"
                      placeholder="Adres e-mail"
                      className={meta.error && "invalid"}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </fieldset>
                )}
              </Field>
            </div>
            <div className="col-xl-6">
              <Field name="emailconfirmation">
                {({ field, meta }: FieldProps) => (
                  <fieldset>
                    <input
                      type="text"
                      placeholder="Powtórz adres e-mail"
                      className={meta.error && "invalid"}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </fieldset>
                )}
              </Field>
            </div>
            <div className="col-xl-6">
              <Field name="phone">
                {({ field, meta }: FieldProps) => (
                  <fieldset>
                    <input
                      type="text"
                      placeholder="Numer telefonu"
                      className={meta.error && "invalid"}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </fieldset>
                )}
              </Field>
            </div>
            <div className="col-xl-6">
              <Field name="nip">
                {({ field, meta }: FieldProps) => (
                  <fieldset>
                    <input
                      type="text"
                      placeholder="Numer NIP firmy"
                      className={meta.error && "invalid"}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <div className="error">{meta.error}</div>
                    )}
                  </fieldset>
                )}
              </Field>
            </div>
          </div>

          <div>
            <Button
              className="button black-squeeze-button"
              onClick={cancelHandler}
            >
              Anuluj
            </Button>
            <Button className="submit" type="submit">
              Zresetuj hasło
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default ResetForm;
