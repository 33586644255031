import React from "react";
import logo from "../../../images/logo.png";
import login from "../../../images/login.png";
import LoginForm from "./../../components/loginForm";
import { Link } from "react-router-dom";
import routes from "../../routes";

import "./login.scss";

const Login: React.FC = () => {
  return (
    <article className="login-page">
      <div className="row no-gutters">
        <div className="col-lg-6 ">
          <div className="img-wrapper">
            <img src={login} alt="login page background" />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="content">
            <img src={logo} className="App-logo" alt="logo" />

            <h3>Witaj w aplikacji Skyrocket!</h3>
            <LoginForm />
            <div className="footer">
              <p>Nie masz konta?</p>
              <Link
                to={routes.register}
                className="button black-squeeze-button"
              >
                Zarejestruj się
              </Link>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Login;
