import React, { useEffect, useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikErrors,
  FieldProps,
  FormikProps,
} from "formik";
import { useHistory } from "react-router-dom";
import Button from "./../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { eventBus } from "./../../helpers/eventBus";
import { Dispatch } from "redux";
import routes from "./../../routes";
import { RootState, DefaultApiResult } from "./../../redux/reducers";
import {
  addCompany,
  getCompanyInfo,
  ADD_COMPANY_SUCCESS,
} from "./../../redux/company/actions";
import displayMessage from "../../helpers/displayMessage";

import "./companyForm.scss";

interface Address {
  city: string;
  street: string;
  buildingNumber: string;
  apartmentNumber: string;
  postCode: string;
  county: string;
}
interface Props {
  setCompany: (e: any) => void;
}
interface MyFormValues {
  nip: string;
  name: string;
  headquarterAddress: Address;
  useHeadquarterAddressOnly: boolean;
  correspondenceAddress: Address | undefined;
  bankAccountNumber: number | string;
  vatTaxesPayer: boolean;
  phoneNumber: string;
  confirmData: boolean;
  approvalRegulations: boolean;
}

const initialValues: MyFormValues = {
  nip: "",
  name: "",
  headquarterAddress: {
    city: "",
    street: "",
    buildingNumber: "",
    apartmentNumber: "",
    postCode: "",
    county: "",
  },
  useHeadquarterAddressOnly: false,
  correspondenceAddress: {
    city: "",
    street: "",
    buildingNumber: "",
    apartmentNumber: "",
    postCode: "",
    county: "",
  },
  bankAccountNumber: "",
  vatTaxesPayer: false,
  confirmData: false,
  phoneNumber: "",
  approvalRegulations: false,
};

const CompanyForm: React.FC<Props> = ({ setCompany }) => {
  const [correAddress, setCorreAddress] = useState(false);
  const [initValues, setInitValues] = useState(initialValues);
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();

  const data = useSelector<RootState, DefaultApiResult>(
    (state) => state.company
  );

  const getGusInfo = (nip: string) => {
    dispatch(getCompanyInfo({ nip }));
    setInitValues({ ...initValues, nip: nip });
  };

  useEffect(() => {
    if (data.data?.info?.name) {
      setInitValues({
        ...initValues,
        name: data.data.info.name,
        headquarterAddress: {
          ...initValues.headquarterAddress,
          city: data.data.info.city,
          county: data.data.info.county,
          postCode: data.data.info.postCode,
          street: data.data.info.street,
        },
      });
    }
    eventBus(ADD_COMPANY_SUCCESS).then((payload: any) => {
      if (payload) {
        setCompany(false);
      }
    });
    if (data.error) {
      displayMessage(data.error, "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, history]);
  return (
    <div className="company-form">
      <Formik
        enableReinitialize
        initialValues={initValues}
        validate={(values: MyFormValues) => {
          const errors: FormikErrors<MyFormValues> = {};
          if (!values.confirmData) {
            errors.confirmData = "To pole jest wymagane";
          }
          if (!values.name) {
            errors.name = "To pole jest wymagane";
          }
          if (!values.nip) {
            errors.nip = "To pole jest wymagane";
          }
          if (!values.phoneNumber) {
            errors.phoneNumber = "To pole jest wymagane";
          }
          if (!values.approvalRegulations) {
            errors.approvalRegulations = "To pole jest wymagane";
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          dispatch(
            addCompany({ ...values, useHeadquarterAddressOnly: correAddress })
          );

          actions.setSubmitting(false);
        }}
      >
        {(props: FormikProps<MyFormValues>) => (
          <Form>
            <div className="row">
              <div className="col-xl-12">
                <h4>Dane firmy</h4>
              </div>
              <div className="col-xl-6">
                <Field name="nip">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="NIP firmy"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-6 gus">
                <Button
                  className={data.loading ? "submit loading" : "submit"}
                  onClick={() => getGusInfo(props.values.nip)}
                  type="button"
                >
                  Pobierz z GUS
                </Button>
              </div>
              <div className="col-xl-6">
                <Field name="name">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Nazwa firmy"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-12">
                <h4>Adres siedziby</h4>
              </div>
              <div className="col-xl-6">
                <Field name="headquarterAddress.street">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="ulica"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-6">
                <Field name="headquarterAddress.buildingNumber">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="numer mieszkania, lokalu"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-6">
                <Field name="headquarterAddress.city">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Miejscowość"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-6">
                <Field name="headquarterAddress.apartmentNumber">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Lokal"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-6">
                <Field name="headquarterAddress.postCode">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Kod pocztowy"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-6">
                <Field name="headquarterAddress.county">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Powiat"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-12">
                <h4>
                  Adres korespondencyjny
                  <>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        onChange={() => setCorreAddress(!correAddress)}
                      />
                      <div className="checkmark"></div>
                      Taki sam jak siedziby
                    </label>
                  </>
                </h4>
              </div>
              {!correAddress && (
                <>
                  <div className="col-xl-6">
                    <Field name="correspondenceAddress.street">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="ulica"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-6">
                    <Field name="correspondenceAddress.buildingNumber">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="numer mieszkania, lokalu"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-6">
                    <Field name="correspondenceAddress.city">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Miejscowość"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-6">
                    <Field name="correspondenceAddress.apartmentNumber">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Lokal"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-6">
                    <Field name="correspondenceAddress.postCode">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Kod pocztowy"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-xl-6">
                    <Field name="correspondenceAddress.county">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Powiat"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  </div>
                </>
              )}
              <div className="col-xl-12">
                <h4>
                  Dane do rozliczeń{" "}
                  <span>
                    (Dodaj numer konta, który posłuży nam do wypłaty dodatkowego
                    wynagrodzenia za udział w konkursach)
                  </span>
                </h4>
              </div>
              <div className="col-xl-6">
                <Field name="bankAccountNumber">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Numer konta bankowego"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-6">
                <Field name="vatTaxesPayer">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className={meta.error && "invalid"}
                          {...field}
                        />
                        <div className="checkmark"></div>
                        Jestem płatnikiem podatku VAT 23%
                      </label>
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
              <div className="col-xl-12">
                <h4>Dane kontaktowe</h4>
              </div>
              <div className="col-xl-6">
                <Field name="phoneNumber">
                  {({ field, meta }: FieldProps) => (
                    <fieldset>
                      <input
                        type="text"
                        placeholder="Numer telefonu"
                        className={meta.error && "invalid"}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <div className="error">{meta.error}</div>
                      )}
                    </fieldset>
                  )}
                </Field>
              </div>
            </div>
            <div className="row footer">
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-12">
                    <Field name="confirmData">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              className={meta.error && "invalid"}
                              {...field}
                            />
                            <div className="checkmark"></div>
                            Potwierdzam poprawność danych
                            {meta.touched && meta.error && (
                              <div className="error">{meta.error}</div>
                            )}
                          </label>
                        </fieldset>
                      )}
                    </Field>
                  </div>
                  <div className="col-12">
                    <Field name="approvalRegulations">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              className={meta.error && "invalid"}
                              {...field}
                            />
                            <div className="checkmark"></div>
                            Akceptuję Regulamin
                            {meta.touched && meta.error && (
                              <div className="error">{meta.error}</div>
                            )}
                          </label>
                        </fieldset>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <Button
                  className={data.loading ? "submit loading" : "submit"}
                  type="submit"
                >
                  Zapisz i przejdź dalej
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyForm;
