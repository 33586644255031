export const UPDATE_POFILE_REQUEST = "UPDATE_POFILE_REQUEST";
export const UPDATE_POFILE_SUCCESS = "UPDATE_POFILE_SUCCESS";
export const UPDATE_POFILE_FAILURE = "UPDATE_POFILE_FAILURE";
export const UPDATE_PASSWORRD_REQUEST = "UPDATE_PASSWORRD_REQUEST";
export const UPDATE_PASSWORRD_SUCCESS = "UPDATE_PASSWORRD_SUCCESS";
export const UPDATE_PASSWORRD_FAILURE = "UPDATE_PASSWORRD_FAILURE";
export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_FAILURE = "UPDATE_AVATAR_FAILURE";

export const updateProfile = (data?: object) => {
  return {
    method: "PUT",
    type: UPDATE_POFILE_REQUEST,
    data,
  };
};

export const updatePassword = (data?: object) => {
  return {
    method: "PUT",
    type: UPDATE_PASSWORRD_REQUEST,
    data,
  };
};

export const updateAvatar = (data?: FormData) => {
  return {
    method: "POST",
    type: UPDATE_AVATAR_REQUEST,
    formData: data,
  };
};
