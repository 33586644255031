import React from "react";

interface props {
  name: string;
}

const icons: any = {
  users: (
    <svg width="17.17" height="20.604">
      <path
        className="a"
        fill="#c8c3df"
        d="M10.3 4.292a5.087 5.087 0 00-.52-2.1A3.981 3.981 0 006.009 0a4.933 4.933 0 00-3.052 1 3.247 3.247 0 01-2.1.714.858.858 0 00-.836 1.056A3.287 3.287 0 001.6 4.894c.059.035.119.069.18.1a4.292 4.292 0 008.526-.7zM3.91 2.431a3.247 3.247 0 012.1-.714 2.264 2.264 0 012.215 1.2 3.172 3.172 0 01.264.715c-1.638.33-4.549.669-6.008-.208a2.081 2.081 0 01-.236-.164 5.5 5.5 0 001.665-.829zm2.1 4.437A2.576 2.576 0 013.8 5.606a10.843 10.843 0 001.484.1 17.738 17.738 0 003.05-.292 2.579 2.579 0 01-2.325 1.454z"
      />
      <path
        className="a"
        fill="#c8c3df"
        d="M12.019 10.302c-.152 0-.3.008-.45.021a3.427 3.427 0 00-2.984-1.738H3.434A3.438 3.438 0 000 12.019v7.726a.858.858 0 00.858.858h11.16a5.151 5.151 0 100-10.3zm-10.3 1.717a1.719 1.719 0 011.717-1.717h5.151a1.714 1.714 0 011.215.5 5.146 5.146 0 00-1.618 8.083H1.717zm10.3 6.868a3.434 3.434 0 113.434-3.434 3.434 3.434 0 01-3.434 3.432z"
      />
      <path
        className="a"
        fill="#c8c3df"
        d="M11.725 15.205l-1.039-.519-.768 1.536 1.717.858a.858.858 0 001.1-.292l1.717-2.575-1.429-.952z"
      />
    </svg>
  ),
  customer: (
    <svg width="15.243" height="20.038">
      <g transform="translate(-20)" opacity=".301">
        <path
          className="b"
          fill="#493895"
          d="M30.021 4.175a4.175 4.175 0 10-4.175 4.175 4.175 4.175 0 004.175-4.175zm-4.176 2.506a2.505 2.505 0 112.505-2.505 2.505 2.505 0 01-2.505 2.505zM34.069 12.279a2.881 2.881 0 00-2.378-.606v-.816a2.508 2.508 0 00-2.505-2.506h-6.681A2.508 2.508 0 0020 10.856v6.681a.835.835 0 00.835.835H27.1a18.434 18.434 0 002.545 1.579.868.868 0 00.752 0c.368-.186 3.624-1.868 4.546-3.712a3.431 3.431 0 00-.874-3.96zm-12.4-1.422a.835.835 0 01.835-.835h6.681a.835.835 0 01.835.835v1.494c-2.119-1.326-3.52-.51-4.048-.07a3.432 3.432 0 00-.874 3.962 3.566 3.566 0 00.281.462H21.67zm11.779 4.637c-.459.919-2.209 2.1-3.429 2.77-1.219-.667-2.967-1.848-3.428-2.77a1.752 1.752 0 01.451-1.932 1.139 1.139 0 01.752-.264 3.214 3.214 0 011.725.733.835.835 0 001 0c.6-.448 1.714-1.106 2.477-.469a1.752 1.752 0 01.45 1.932z"
        />
        <circle
          className="b"
          fill="#493895"
          cx="1"
          cy="1"
          r="1"
          transform="translate(31 14)"
        />
      </g>
    </svg>
  ),
  settings: (
    <svg width="19.999" height="20" viewBox="0 0 19.999 20">
      <path
        d="M19.877 8.483a.856.856 0 00-.56-.679l-1.576-.56a8.184 8.184 0 00-.316-.769l.717-1.509a.858.858 0 00-.084-.876 10.019 10.019 0 00-2.146-2.146.856.856 0 00-.875-.083l-1.508.716a8.169 8.169 0 00-.769-.316L12.2.687a.857.857 0 00-.677-.56 9.093 9.093 0 00-3.037 0 .857.857 0 00-.677.56l-.56 1.576a8.139 8.139 0 00-.769.316l-1.51-.717a.856.856 0 00-.875.083 10.019 10.019 0 00-2.146 2.146.857.857 0 00-.084.876l.717 1.509a8.208 8.208 0 00-.316.769L.69 7.8a.857.857 0 00-.56.678A9.907 9.907 0 000 10a9.914 9.914 0 00.13 1.517.856.856 0 00.56.679l1.576.56a8.3 8.3 0 00.316.769l-.717 1.508a.857.857 0 00.083.875 10.02 10.02 0 002.146 2.146.854.854 0 00.875.083l1.508-.716a8.168 8.168 0 00.769.316l.56 1.576a.857.857 0 00.677.56A9.926 9.926 0 0010 20a9.925 9.925 0 001.518-.127.857.857 0 00.677-.56l.56-1.576a8.168 8.168 0 00.769-.316l1.508.716a.855.855 0 00.876-.083 10.019 10.019 0 002.146-2.146.857.857 0 00.083-.875l-.717-1.508a8.208 8.208 0 00.316-.769l1.576-.56a.856.856 0 00.56-.679A9.913 9.913 0 0020 10a9.911 9.911 0 00-.123-1.517zm-1.629 2.274l-1.478.526a.856.856 0 00-.535.565 6.44 6.44 0 01-.517 1.256.857.857 0 00-.021.778l.67 1.41a8.276 8.276 0 01-1.072 1.071l-1.41-.67a.855.855 0 00-.778.021 6.485 6.485 0 01-1.257.517.856.856 0 00-.564.535l-.526 1.479a7 7 0 01-1.513 0l-.526-1.479a.857.857 0 00-.564-.535 6.464 6.464 0 01-1.257-.517.852.852 0 00-.778-.021l-1.41.67a8.338 8.338 0 01-1.072-1.072l.67-1.41a.857.857 0 00-.021-.778 6.451 6.451 0 01-.517-1.257.856.856 0 00-.535-.564l-1.477-.525a6.943 6.943 0 010-1.513l1.478-.526a.856.856 0 00.535-.564A6.5 6.5 0 014.289 6.9a.856.856 0 00.021-.777l-.67-1.41a8.331 8.331 0 011.072-1.076l1.41.67a.855.855 0 00.778-.021 6.453 6.453 0 011.257-.516.857.857 0 00.564-.535l.526-1.479a6.929 6.929 0 011.513 0l.526 1.479a.857.857 0 00.564.535 6.464 6.464 0 011.257.517.852.852 0 00.778.021l1.41-.67a8.331 8.331 0 011.072 1.072l-.67 1.41a.856.856 0 00.021.777 6.476 6.476 0 01.517 1.257.856.856 0 00.535.565l1.478.526a6.947 6.947 0 010 1.513z"
        className="a"
        fill="#c8c3df"
        transform="translate(-.004)"
      ></path>
      <path
        d="M10.003 5.829a3.968 3.968 0 103.968 3.968 3.972 3.972 0 00-3.968-3.968zm0 6.222a2.254 2.254 0 112.254-2.254 2.256 2.256 0 01-2.254 2.256z"
        className="a"
        fill="#c8c3df"
        transform="translate(-.004)"
      ></path>
    </svg>
  ),
  page: (
    <svg width="16.667" height="20" viewBox="0 0 16.667 20">
      <g className="a" opacity="0.301">
        <path
          d="M16.406 5.937a3.027 3.027 0 00-.625-.989L11.719.885a3.035 3.035 0 00-.99-.625A3.006 3.006 0 009.584 0h-8.33a1.205 1.205 0 00-.885.365 1.205 1.205 0 00-.365.885v17.5A1.245 1.245 0 001.254 20h14.163a1.245 1.245 0 001.25-1.25V7.083a3 3 0 00-.261-1.146zM10 1.771a1.425 1.425 0 01.534.286l4.076 4.076a1.426 1.426 0 01.286.534h-4.9zm5 16.563H1.667V1.667h6.667v5.416a1.245 1.245 0 001.25 1.25h5.417z"
          className="b"
          fill="#493895"
          transform="translate(-36.546) translate(36.546)"
        ></path>
        <path
          d="M12.917 13.333H3.75a.4.4 0 00-.417.416v.833a.4.4 0 00.417.417h9.167a.4.4 0 00.416-.417v-.833a.4.4 0 00-.416-.416zM3.45 10.117a.406.406 0 00-.117.3v.833a.4.4 0 00.417.417h9.167a.4.4 0 00.416-.417v-.833a.4.4 0 00-.416-.417H3.75a.406.406 0 00-.3.117z"
          className="b"
          fill="#493895"
          transform="translate(-36.546) translate(36.546)"
        ></path>
      </g>
    </svg>
  ),
  graduation: (
    <svg width="19.188" height="20.046" viewBox="0 0 19.188 20.046">
      <g className="a" opacity="0.3">
        <path
          d="M19.633 9.179H18.8V4.172a.829.829 0 00-.522-.772L9.932.062a.786.786 0 00-.616 0L.971 3.4a.843.843 0 000 1.554L3.788 6.08v3.307a.8.8 0 00.188.522c1.054 1.3 3.38 1.617 4.673 1.69.25.01.5.021.762.021 2.191 0 4.673-.48 5.82-1.638a.827.827 0 00.24-.595V6.07l1.669-.67v3.779h-.834v1.669h.834v1.669h1.669v-1.669h.834V9.179zM13.792 9a9.485 9.485 0 01-5.07.928 6.2 6.2 0 01-3.276-.887V6.738l3.86 1.544a.788.788 0 00.616 0l3.859-1.544V9zM9.619 6.613l-6.1-2.441 6.1-2.441 6.1 2.441zm0 0M17.568 14.311a.838.838 0 00-.814-.042 15.351 15.351 0 01-5.5 1.116l1.45-1.45-1.179-1.179-1.909 1.919-1.909-1.909-1.179 1.179 1.45 1.45a15.585 15.585 0 01-5.5-1.116.852.852 0 00-1.21.751v4.173a.853.853 0 001.21.751 17.488 17.488 0 016.3-1.137v.4h1.669v-.407c2.67.042 5.163.407 6.217 1.106a.845.845 0 001.293-.7v-4.172a.836.836 0 00-.389-.733zM2.943 17.973v-1.721a21.527 21.527 0 005.842.845v.031a23.46 23.46 0 00-5.842.845zm13.352-.073a21.292 21.292 0 00-5.842-.793v-.021a22.388 22.388 0 005.842-.845zm0 0"
          className="b"
          fill="#493895"
          transform="translate(-.455 .001)"
        ></path>
      </g>
    </svg>
  ),
  promote: (
    <svg width="22.003" height="20" viewBox="0 0 22.003 20">
      <g className="a" opacity="0.3">
        <path
          d="M11.319 13.65l-1.742.611.533 1.519c-.533 2.908-4.646 6.22-6.341 7.364l-2.5.878A.932.932 0 00.7 25.2l2.75 7.835a.931.931 0 001.177.565l1.742-.611a.923.923 0 00.608-1l-.518-3.67c2.4-.088 6.728.133 8.695 1.838l.533 1.519 1.742-.611zm-6.433 17.9l-.916-2.609.679-.238.394 2.8zm2.53-5.094c-1.176 0-1.994.072-2.1.081h-.011c-.023 0-.045.006-.068.01l-.038.007a.911.911 0 00-.1.029l-1.741.617-.611-1.742 1.742-.611a.924.924 0 00.2-.1c.219-.145 4.4-2.929 6.345-6.327l3.191 9.1a19.6 19.6 0 00-6.811-1.058zM16.501 22.754l.618 1.739a2.768 2.768 0 00-1.854-5.217l.618 1.739a.923.923 0 01.618 1.739z"
          className="b"
          fill="#493895"
          transform="translate(-.651 -13.65)"
        ></path>
        <path
          d="M22.279 19.721a6.467 6.467 0 00-8.25-3.924l.618 1.739a4.616 4.616 0 013.09 8.7l.618 1.739a6.46 6.46 0 003.924-8.25z"
          className="b"
          fill="#493895"
          transform="translate(-.651 -13.65)"
        ></path>
      </g>
    </svg>
  ),
  wallet: (
    <svg width="18.667" height="17.346" viewBox="0 0 18.667 17.346">
      <g className="a" opacity="0.3">
        <path
          d="M17.889 7.235h-1.555V5.679A2.292 2.292 0 0014 3.346V2.335A2.408 2.408 0 0010.967.079L.544 3.346A.937.937 0 000 4.124v12.444a.735.735 0 00.778.778H14a2.292 2.292 0 002.333-2.333v-1.556h1.556a.735.735 0 00.778-.778V8.013a.735.735 0 00-.778-.778zm-6.456-5.6a.8.8 0 011.011.778v.933H5.911zm3.344 13.378a.735.735 0 01-.777.774H1.556V4.902H14a.735.735 0 01.778.778v1.556a2.292 2.292 0 00-2.333 2.333v1.556a2.292 2.292 0 002.333 2.333zm2.333-3.111h-2.332a.735.735 0 01-.778-.778V9.568a.735.735 0 01.778-.778h2.333z"
          className="b"
          fill="#493895"
          transform="translate(0 -18.113) translate(0 18.113)"
        ></path>
        <path
          d="M4.667 3.113v-.778A1.56 1.56 0 003.111.779V.001H1.555v.778A1.56 1.56 0 00-.001 2.335v1.556a1.56 1.56 0 001.556 1.556h1.556v1.556H1.555v-.778H-.001v.778a1.56 1.56 0 001.556 1.556v.778h1.556v-.778A1.56 1.56 0 004.667 7V5.444a1.56 1.56 0 00-1.556-1.556H1.555V2.333h1.556v.78h1.556z"
          className="b"
          fill="#493895"
          transform="translate(0 -18.113) translate(5.445 23.792)"
        ></path>
        <circle
          cx="0.778"
          cy="0.778"
          r="0.778"
          className="b"
          fill="#493895"
          transform="translate(0 -18.113) translate(14.778 27.681)"
        ></circle>
      </g>
    </svg>
  ),
  edit: (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g className="a" opacity="0.3">
        <path
          d="M19.219 13.75a.781.781 0 00.781-.781V3.125A3.129 3.129 0 0016.875 0H3.125A3.129 3.129 0 000 3.125v13.75A3.129 3.129 0 003.125 20h13.75A3.129 3.129 0 0020 16.875a.781.781 0 00-1.562 0 1.564 1.564 0 01-1.562 1.563h-2.97v-3.047h1.406a.781.781 0 100-1.562h-1.406v-3.048h4.531v2.187a.781.781 0 00.782.782zM6.094 18.438H3.125a1.564 1.564 0 01-1.562-1.562v-1.485h4.531zm0-4.609H1.563v-3.048h4.531zm0-4.609H1.563V6.172h4.531zm0-4.609H1.563V3.125a1.564 1.564 0 011.562-1.562h2.969zm6.25 13.828H7.656v-3.048h4.688zm0-4.609H7.656v-3.049h4.688zm0-4.609H7.656V6.172h4.688zm0-4.609H7.656V1.563h4.688zm1.563-3.047h2.969a1.564 1.564 0 011.563 1.563v1.481h-4.533zm0 7.656V6.172h4.531v3.047z"
          className="b"
          fill="#493895"
        ></path>
      </g>
    </svg>
  ),
  clipboard: (
    <svg width="20.034" height="20" viewBox="0 0 20.034 20">
      <g className="a" opacity="0.3">
        <path
          d="M15.026 6.644V2.47a.789.789 0 00-.835-.835H9.85a2.482 2.482 0 00-4.675 0H.835A.789.789 0 000 2.47v16.694a.789.789 0 00.835.835h13.356a.789.789 0 00.835-.835v-.835H1.67V3.305h1.67v1.67a.789.789 0 00.835.835h6.678a.789.789 0 00.835-.835v-1.67h1.67v3.339h1.669zm-5.009-2.5H5.009v-.839h.835a.789.789 0 00.835-.835.789.789 0 01.835-.835.789.789 0 01.835.835.789.789 0 00.835.835h.835z"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(0 .436)"
        ></path>
        <circle
          cx="0.835"
          cy="0.835"
          r="0.835"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(3.339 7.915)"
        ></circle>
        <circle
          cx="0.835"
          cy="0.835"
          r="0.835"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(3.339 11.254)"
        ></circle>
        <circle
          cx="0.835"
          cy="0.835"
          r="0.835"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(3.339 14.593)"
        ></circle>
        <path
          d="M0 0H4.174V1.67H0z"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(6.678 7.915)"
        ></path>
        <path
          d="M0 0H2.504V1.67H0z"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(6.678 11.254)"
        ></path>
        <path
          d="M0 0H3.339V1.67H0z"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(6.678 14.593)"
        ></path>
        <path
          d="M5.009 0a5.009 5.009 0 105.009 5.009A5.023 5.023 0 005.009 0zm0 8.348a3.339 3.339 0 113.339-3.339 3.349 3.349 0 01-3.339 3.339z"
          className="b"
          fill="#493895"
          transform="translate(0 -.436) translate(10.017 7.915)"
        ></path>
        <text
          className="c"
          transform="translate(0 -.436) translate(14 14.436)"
          fill="#493895"
        >
          <tspan x="0" y="0">
            ?
          </tspan>
        </text>
      </g>
    </svg>
  ),
  certificate: (
    <svg width="20.034" height="17.794" viewBox="0 0 20.034 17.794">
      <g className="a" opacity="0.3">
        <path
          d="M19.231.16H.866a.835.835 0 00-.835.835v12.521a.835.835 0 00.835.835h10.466a3.362 3.362 0 001.076 1.132l-.648 1.943 1.584.528.647-1.942c.076.005.153.009.23.009s.154 0 .23-.009l.647 1.942 1.584-.528-.648-1.943a3.365 3.365 0 001.077-1.132h2.119a.835.835 0 00.835-.835V.995a.835.835 0 00-.834-.835zm-6.678 12.522a1.669 1.669 0 111.669 1.669 1.669 1.669 0 01-1.669-1.669zm5.843 0h-.835a3.339 3.339 0 10-6.678 0H1.7V1.83h16.7zm0 0"
          className="b"
          fill="#493895"
          transform="translate(-.031 -.16)"
        ></path>
        <path
          d="M5.875 3.5h8.347v1.666H5.875zm0 0M4.205 6.835h3.339v1.67H4.205zm0 0M9.213 6.835h3.339v1.67H9.213zm0 0M4.205 10.177h5.009v1.669H4.205zm0 0"
          className="b"
          fill="#493895"
          transform="translate(-.031 -.16)"
        ></path>
      </g>
    </svg>
  ),
  shield: (
    <svg width="16.667" height="20" viewBox="0 0 16.667 20">
      <g className="a" opacity="0.3">
        <path
          d="M31.168 16.668l1.358-2.038a.833.833 0 00-.693-1.3h-2.826c2.826-2.519 2.826-3.728 2.826-4.167V2.5A.833.833 0 0031 1.667c-2.309 0-6.317-1.591-6.357-1.607a.834.834 0 00-.619 0c-.04.016-4.045 1.607-6.357 1.607a.833.833 0 00-.833.833v6.668c0 .44 0 1.648 2.826 4.167h-2.827a.833.833 0 00-.693 1.3l1.36 2.033-1.36 2.037a.833.833 0 00.693 1.3h15a.833.833 0 00.693-1.3zM18.5 9.168V3.287a26.916 26.916 0 005.833-1.56 26.9 26.9 0 005.833 1.56v5.88c0 .208-.184 1.382-3.773 4.167h-4.12C18.686 10.551 18.5 9.376 18.5 9.168zm10.974 7.962l.8 1.2H18.391l.8-1.2a.833.833 0 000-.924l-.8-1.2h11.885l-.8 1.2a.833.833 0 00-.002.924z"
          className="b"
          fill="#493895"
          transform="translate(-16 -.001)"
        ></path>
        <path
          d="M26.821 10.925a.833.833 0 00.332-.815l-.308-1.793 1.3-1.27a.833.833 0 00-.462-1.421l-1.8-.262-.805-1.631a.833.833 0 00-1.495 0l-.8 1.631-1.8.262a.833.833 0 00-.462 1.421l1.3 1.27-.308 1.793a.833.833 0 001.209.878l1.61-.847 1.61.847a.841.841 0 00.879-.063zm-1.453-3.5a.833.833 0 00-.24.738l.1.561-.5-.265a.833.833 0 00-.776 0l-.5.265.1-.561a.833.833 0 00-.24-.738l-.408-.4.563-.082a.833.833 0 00.627-.456l.252-.51.252.51a.833.833 0 00.627.456l.563.082z"
          className="b"
          fill="#493895"
          transform="translate(-16 -.001)"
        ></path>
      </g>
    </svg>
  ),
  basket: (
    <svg width="23.704" height="20" viewBox="0 0 23.704 20">
      <path
        d="M14.371 15.142l.895-3.519a.926.926 0 111.795.456l-.895 3.521a.926.926 0 11-1.795-.456zm-6.833.458a.926.926 0 001.795-.456l-.895-3.519a.926.926 0 10-1.795.456zM23.7 8.148a.926.926 0 01-.926.926h-1.128l-2.6 10.226a.926.926 0 01-.9.7H5.556a.926.926 0 01-.9-.7l-2.6-10.228H.926a.926.926 0 010-1.852h3.32L11.2.271a.926.926 0 011.309 0l6.951 6.951h3.32a.926.926 0 01.92.926zM6.865 7.222h9.974l-4.987-4.987zm12.87 1.852H3.969l2.307 9.074h11.152zm-7.883 7.315a.926.926 0 00.926-.926v-3.7a.926.926 0 10-1.852 0v3.7a.926.926 0 00.926.926z"
        className="a"
        opacity="0.3"
        fill="#493895"
      ></path>
    </svg>
  ),
  speed: (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g className="a" opacity="0.3">
        <path
          d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18.333A8.333 8.333 0 1118.333 10 8.333 8.333 0 0110 18.333z"
          className="b"
          fill="#493895"
        ></path>
        <path
          d="M10 3.333A6.674 6.674 0 003.333 10H5a5 5 0 115 5v1.667a6.667 6.667 0 000-13.333z"
          className="b"
          fill="#493895"
        ></path>
        <path
          d="M11.998 11.501c.639-.852.8-3.175.833-4.134a.833.833 0 00-1.1-.823c-.91.3-3.1 1.1-3.736 1.957a2.5 2.5 0 001.995 4 2.544 2.544 0 00.359-.026 2.483 2.483 0 001.649-.974zm-2.5-.835a.833.833 0 01-.167-1.167 6.221 6.221 0 011.74-.931 6.244 6.244 0 01-.406 1.931.833.833 0 01-1.167.167z"
          className="b"
          fill="#493895"
        ></path>
      </g>
    </svg>
  ),
};

const Icon = ({ name }: props) => (
  <span className="icon">{icons[name] || icons.users}</span>
);

export default Icon;
