import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldProps, FormikProps } from "formik";
import Button from "./../../components/Button/Button";
import { useHistory } from "react-router-dom";
import { recommendation } from "./../../redux/auth/actions";
import { RootState, AuthApiResult } from "./../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import displayMessage from "./../../helpers/displayMessage";
import routes from "../../routes";

import "./survey.scss";

interface MyFormValues {
  source: string;
  email?: string;
  comment?: string;
}

const initialValues: MyFormValues = {
  source: "",
  email: "",
  comment: "",
};

const Survey: React.FC = () => {
  const [stateSource, setStateSource] = useState("");
  const history = useHistory();
  const dispatch: Dispatch = useDispatch();

  const data = useSelector<RootState, AuthApiResult>((state) => state.auth);

  useEffect(() => {
    if (data.error) {
      displayMessage(data.error, "error");
    }
  }, [data.error]);

  return (
    <article className="survey-page">
      <div className="modal-wrapper">
        <h4>Skąd dowiedziałeś się o naszej aplikacji?</h4>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            dispatch(recommendation({ ...values, source: stateSource }));
            history.push(routes.home);
            actions.setSubmitting(false);
          }}
        >
          {(props: FormikProps<MyFormValues>) => (
            <Form>
              <fieldset>
                <div>
                  <label className="radio-button">
                    <input
                      type="radio"
                      name="source"
                      value="Facebook"
                      onChange={(e) => setStateSource(e.target.value)}
                    />
                    <span>Facebook</span>
                  </label>
                </div>

                <div>
                  <label className="radio-button">
                    <input
                      type="radio"
                      value="InternetAd"
                      name="source"
                      onChange={(e) => setStateSource(e.target.value)}
                    />
                    <span>Reklama w internecie</span>
                  </label>
                </div>
                <div>
                  <label className="radio-button">
                    <input
                      type="radio"
                      value="GoogleAd"
                      name="source"
                      onChange={(e) => setStateSource(e.target.value)}
                    />
                    <span>Reklama Google</span>
                  </label>
                </div>
                <div>
                  <label className="radio-button">
                    <input
                      type="radio"
                      value="CallCenter"
                      name="source"
                      onChange={(e) => setStateSource(e.target.value)}
                    />
                    <span>Call center</span>
                  </label>
                </div>
                <div>
                  <label className="radio-button">
                    <input
                      type="radio"
                      value="SalesRep"
                      name="source"
                      onChange={(e) => setStateSource(e.target.value)}
                    />
                    <span>Przedstawiciel handlowy</span>
                  </label>
                  {stateSource === "SalesRep" && (
                    <Field name="email">
                      {({ field, meta }: FieldProps) => (
                        <fieldset>
                          <input
                            type="text"
                            placeholder="Adres e-mail przedstawiciela"
                            className={meta.error && "invalid"}
                            {...field}
                          />
                          {meta.touched && meta.error && (
                            <div className="error">{meta.error}</div>
                          )}
                        </fieldset>
                      )}
                    </Field>
                  )}
                </div>
                <div>
                  <label className="radio-button">
                    <input
                      type="radio"
                      value="Friend"
                      name="source"
                      onChange={(e) => setStateSource(e.target.value)}
                    />
                    <span>Znajomy</span>
                  </label>
                </div>
                <div>
                  <label className="radio-button">
                    <input
                      type="radio"
                      value="Others"
                      name="source"
                      onChange={(e) => setStateSource(e.target.value)}
                    />
                    <span>Inne</span>
                  </label>
                  {stateSource === "Others" && (
                    <Field name="comment" component="textarea"></Field>
                  )}
                </div>
              </fieldset>
              <div className="actions-wrapper">
                <Button className="submit" type="submit">
                  Zapisz i przejdź do aplikacji
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </article>
  );
};

export default Survey;
